<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="items"
  >
    <template slot="item" slot-scope="{ item }">
      {{ $t(`nationalities.${item}`) }}
    </template>
    <template slot="selection" slot-scope="{ item }">
      {{ $t(`nationalities.${item}`) }}
    </template>
  </v-autocomplete>
</template>

<script>
  import nationalities from "./nationalities";

  export default {
    inheritAttrs: false,
    name: "MaritalStatusesPicker",
    props: {
      value: [String, Array]
    },
    data: () => ({
      items: nationalities,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
